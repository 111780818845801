import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { EpochVotingLeaderboardRecord } from 'providers/VortexDataProvider';

export const createEpochVotingLeaderboardTableColumns = (
  idealRecord: EpochVotingLeaderboardRecord | undefined,
  epoch: number
): ColumnDef<EpochVotingLeaderboardRecord>[] => {
  return [
    {
      accessorFn: (_row, index) => {
        if (index === 0) return '';
        return index;
      },
      header: 'Ranking',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeName',
      header: 'Validator',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeAddress',
      header: 'Validator Address',
      cell: ({ cell, row }) => {
        return (
          <a
            href={`/vote-history/${row.original.voteAddress}/epoch/${epoch}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.nodeAddress}
          </a>
        );
      },
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.voteAddress, // Include vote address in search context
      header: 'Vote Address (hidden)',
      meta: {
        cellProps: { className: 'd-none' }, // Hide cell
        headerProps: { className: 'd-none' } // Hide header
      }
    },
    {
      accessorFn: row => {
        let idealCredits = idealRecord?.earnedCredits;
        if (idealCredits === undefined) {
          return 'NA';
        } else {
          return idealCredits - row.earnedCredits;
        }
      },
      header: 'Missed Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        let idealNumVotes = idealRecord?.votedSlots;
        if (idealNumVotes === undefined) {
          return 'NA';
        } else {
          return idealNumVotes - row.votedSlots;
        }
      },
      header: 'Missed Slots',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        let idealNumVotes = idealRecord?.votedSlots;
        if (idealNumVotes === undefined) {
          return 'NA';
        }
        return (row.earnedCredits / idealNumVotes).toFixed(5);
      },
      header: 'Avg credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        return (row.totalLatency / row.votedSlots).toFixed(5);
      },
      header: 'Avg latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.continent || 'N/A',
      header: 'Region',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.country || 'N/A',
      header: 'Country',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => row.datacenter || 'N/A',
      header: 'Datacenter',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochLeaderboardTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 table-striped table-with-padding'
        }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochLeaderboardTable;
