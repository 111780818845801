import { CSSProperties, useContext, useEffect, useMemo } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { contactSourceData } from 'data/crm/dashboardData';
import { VortexDataContext, VoterRecord } from 'providers/VortexDataProvider';

echarts.use([TooltipComponent, PieChart]);

// export const contactSourceData = [
//   { name: 'Organic', value: 80, color: 'primary' },
//   { name: 'Paid Search', value: 65, color: 'success' },
//   { name: 'Direct', value: 40, color: 'info' },
//   { name: 'Social', value: 220, color: 'info-light' },
//   { name: 'Referrals', value: 120, color: 'danger-lighter' },
//   { name: 'Others', value: 35, color: 'warning-light' }
// ];

type ChartDataItem = {
  name: string;
  color: string;
  value: number;
};

const getDefaultOptions = (
  data: ChartDataItem[],
  getThemeColor: (name: string) => string,
  isDark: boolean
) => ({
  color: [
    getThemeColor('success'),
    getThemeColor('primary'),
    !isDark ? getThemeColor('info-light') : getThemeColor('info-dark'),
    !isDark ? getThemeColor('warning-light') : getThemeColor('warning-dark'),
    !isDark ? getThemeColor('danger-lighter') : getThemeColor('danger-darker')
  ],
  tooltip: {
    trigger: 'item',
    borderWidth: 0
  },
  responsive: true,
  maintainAspectRatio: false,

  series: [
    {
      name: 'Vote Latency',
      type: 'pie',
      radius: ['55%', '90%'],
      startAngle: 90,
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: getThemeColor('body-bg'),
        borderWidth: 3
      },

      label: {
        show: false
      },
      emphasis: {
        label: {
          show: false
        }
      },
      labelLine: {
        show: false
      },
      data
    }
  ],
  grid: {
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    containLabel: false
  }
});

export const useChartStats = ({
  clusterData,
  tableData
}: {
  clusterData: VoterRecord | undefined;
  tableData: VoterRecord | undefined;
}) => {
  return useMemo(() => {
    if (!clusterData || !tableData) return undefined;

    let stats = {
      minLatency: 0,
      fastLatency: 0,
      delayLatency: 0,
      slowLatency: 0,
      skippedSlots: 0
    };

    for (let i = 0; i < tableData.length; i++) {
      const voterLatency = tableData[i] || 0;
      const optimalLatency = clusterData[i] || 0;

      if (voterLatency === 0) {
        if (optimalLatency === 0) {
          // Cluster skipped this slot
          continue;
        } else {
          stats.skippedSlots += 1;
        }
      } else {
        switch (voterLatency - optimalLatency) {
          case 0: {
            stats.minLatency += 1;
            break;
          }
          case 1: {
            stats.fastLatency += 1;
            break;
          }
          default: {
            stats.slowLatency += 1;
            break;
          }
        }
      }
    }

    return stats;
  }, [clusterData, tableData]);
};

const VoteLatencyPieChart = ({
  style,
  voteId,
  epoch
}: {
  epoch: number;
  voteId: string;
  style: CSSProperties;
}) => {
  const {
    getThemeColor,
    config: { isDark }
  } = useAppContext();
  const context = useContext(VortexDataContext);
  const EPOCH = epoch;
  const VOTE_ID = voteId;
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochVoteRecordState &&
      !context.epochVoteRecordState.isLoading &&
      !context.epochVoteRecordState.data.size
    ) {
      context.epochVoteRecordState.fetchData(VOTE_ID, EPOCH);
      context.epochVoteRecordState.fetchData(IDEAL_RECORD_KEY, EPOCH);
    }
  }, [context]);

  const clusterData = context?.epochVoteRecordState.data
    .get(IDEAL_RECORD_KEY)
    ?.get(EPOCH);
  const tableData = context?.epochVoteRecordState.data.get(VOTE_ID)?.get(EPOCH);

  const chartStats = useChartStats({ clusterData, tableData });
  const chartData = useMemo(() => {
    if (chartStats === undefined) {
      return [];
    }

    return [
      {
        name: 'MIN (1)',
        color: getThemeColor('primary'),
        value: chartStats.minLatency
      },

      {
        name: 'FAST (2)',
        color: getThemeColor('secondary'),
        value: chartStats.fastLatency
      },

      {
        name: 'DELAYED (3-6)',
        color: !isDark
          ? getThemeColor('info-light')
          : getThemeColor('info-darker'),
        value: chartStats.delayLatency
      },

      {
        name: 'SLOW (>6)',
        color: !isDark
          ? getThemeColor('warning-light')
          : getThemeColor('warning-darker'),
        value: chartStats.slowLatency
      },

      {
        name: 'MISSED',
        color: !isDark
          ? getThemeColor('danger-light')
          : getThemeColor('danger-darker'),
        value: chartStats.skippedSlots
      }
    ];
  }, [chartStats, isDark]);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(chartData, getThemeColor, isDark)}
      style={style}
    />
  );
};

export default VoteLatencyPieChart;
