import React, {
  createContext,
  useContext,
  useState,
  useRef,
  ReactNode,
  useCallback
} from 'react';
import axios from 'axios';
import { BlockIncome, EpochIncome, IncomeDetails } from 'data/income';

const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

interface BlockIncomeState {
  data: BlockIncome[] | null;
  isLoading: boolean;
  error: string | null;
  fetchData: (identity: string, epoch: number) => Promise<void>;
}

interface EpochIncomeState {
  // keyed by validator string
  data: Map<string, EpochIncome[]>;
  isLoading: boolean;
  error: string | null;
  fetchData: (identity: string) => Promise<void>;
}

export type VoterRecord = number[];

interface EpochVoteRecordState {
  // keyed by vote string and then by epoch
  data: Map<string, Map<number, VoterRecord>>;
  isLoading: boolean;
  error: string | null;
  fetchData: (voteKey: string, epoch?: number) => Promise<void>;
}

export interface EpochVotingLeaderboardRecord {
  nodeAddress: string;
  nodeName: string | null;
  voteAddress: string;
  votedSlots: number;
  earnedCredits: number;
  totalLatency: number;
  datacenter: string | null;
  continent: string | null;
  country: string | null;
}

export interface EpochVotingLeaderboard {
  epoch: number;
  records: Array<EpochVotingLeaderboardRecord>;
}

interface EpochVotingLeaderboardState {
  // keyed by epoch
  data: Map<number, EpochVotingLeaderboard>;
  isLoading: boolean;
  error: string | null;
  fetchData: (epoch?: number) => Promise<void>;
}

export interface EpochIncomeLeaderboardRecord {
  nodeAddress: string;
  nodeName: string | null;
  medianIncome: IncomeDetails;
  totalSlots: number;
  confirmedSlots: number;
}

export interface EpochIncomeLeaderboard {
  epoch: number;
  records: Array<EpochIncomeLeaderboardRecord>;
}

interface EpochIncomeLeaderboardState {
  // keyed by epoch
  data: Map<number, EpochIncomeLeaderboard>;
  isLoading: boolean;
  error: string | null;
  fetchData: (epoch?: number) => Promise<void>;
}

interface VortexDataState {
  blockIncomeState: BlockIncomeState;
  epochIncomeState: EpochIncomeState;
  epochVoteRecordState: EpochVoteRecordState;
  epochVoteLeaderboardState: EpochVotingLeaderboardState;
  epochIncomeLeaderboardState: EpochIncomeLeaderboardState;
}

// Interface for the provider props to include children
interface VortexDataProviderProps {
  children: ReactNode;
}

export const VortexDataContext = createContext<VortexDataState | undefined>(
  undefined
);

export const useVortexData = () => useContext(VortexDataContext);

// Type guard to verify an object as BlockIncome
function isBlockIncome(obj: any): obj is BlockIncome {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidNumberProperty(obj, 'slot') &&
    isOptionalIncomeDetails(obj['income'])
  );
}

// Function to validate and transform the data
const validateAndTransformData = (data: unknown): BlockIncome[] | null => {
  if (!Array.isArray(data)) return null;

  return data.map(item => {
    if (isBlockIncome(item)) {
      return item;
    }
    throw new Error('Invalid block data format');
  });
};

// Helper function to check if a property exists and is a number
function isValidNumberProperty(obj: any, prop: string): boolean {
  return obj.hasOwnProperty(prop) && typeof obj[prop] === 'number';
}

// Helper function to check if a property exists and is a number
function isOptionalNumberProperty(obj: any, prop: string): boolean {
  return !obj.hasOwnProperty(prop) || typeof obj[prop] === 'number';
}

// Helper function to check if a property exists and is a string
function isValidStringProperty(obj: any, prop: string): boolean {
  return obj.hasOwnProperty(prop) && typeof obj[prop] === 'string';
}

// Helper function to check if a property exists and is an array
function isValidArrayProperty(obj: any, prop: string): boolean {
  return obj.hasOwnProperty(prop) && Array.isArray(obj[prop]);
}

// Type guard to verify an object as IncomeDetails
function isOptionalIncomeDetails(obj: any): obj is IncomeDetails | null {
  return obj === null || isIncomeDetails(obj);
}

// Type guard to verify an object as IncomeDetails
function isIncomeDetails(obj: any): obj is IncomeDetails {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidNumberProperty(obj, 'baseFees') &&
    isValidNumberProperty(obj, 'priorityFees') &&
    isValidNumberProperty(obj, 'mevTips')
  );
}

// Type guard to verify an object as EpochIncome
function isEpochIncome(obj: any): obj is EpochIncome {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidNumberProperty(obj, 'epoch') &&
    isValidNumberProperty(obj, 'stake') &&
    isValidNumberProperty(obj, 'totalSlots') &&
    isValidNumberProperty(obj, 'confirmedSlots') &&
    isValidNumberProperty(obj, 'skippedSlots') &&
    isIncomeDetails(obj['totalIncome']) &&
    isIncomeDetails(obj['medianIncome']) &&
    isIncomeDetails(obj['minIncome']) &&
    isIncomeDetails(obj['maxIncome'])
  );
}

type EpochVoteRecordResponse = {
  epoch: number;
  votesBase64: string;
};

// Type guard to verify an object as EpochVoteRecordResponse
function isEpochVoteRecordResponse(obj: any): obj is EpochVoteRecordResponse {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidStringProperty(obj, 'votesBase64') &&
    isValidNumberProperty(obj, 'epoch')
  );
}

// Type guard to verify an object as EpochVotingLeaderboard
function isEpochVotingLeaderboard(obj: any): obj is EpochVotingLeaderboard {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidNumberProperty(obj, 'epoch') &&
    isValidArrayProperty(obj, 'records')
  );
}

// Type guard to verify an object as EpochIncomeLeaderboard
function isEpochIncomeLeaderboard(obj: any): obj is EpochIncomeLeaderboard {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    isValidNumberProperty(obj, 'epoch') &&
    isValidArrayProperty(obj, 'records')
  );
}

// Function to validate and transform the data
const validateAndTransformEpochIncomeData = (
  data: unknown
): EpochIncome[] | null => {
  if (!Array.isArray(data)) return null;

  return data.map(item => {
    if (isEpochIncome(item)) {
      return item;
    }
    throw new Error('Invalid epoch data format');
  });
};

function base64ToBytes(base64: string): number[] {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

interface VoteRecordResponse {
  votes: number[];
  epoch: number;
}

const validateAndTransformEpochVoteRecordData = (
  data: unknown
): VoteRecordResponse | null => {
  if (Array.isArray(data)) return null;
  if (isEpochVoteRecordResponse(data)) {
    try {
      return {
        epoch: data.epoch,
        votes: base64ToBytes(data.votesBase64)
      };
    } catch (err) {
      console.warn('failed to convert response to array');
    }
  }

  return null;
};

const validateAndTransformEpochVotingLeaderboardData = (
  data: unknown
): EpochVotingLeaderboard | null => {
  if (Array.isArray(data)) return null;
  if (isEpochVotingLeaderboard(data)) {
    return data;
  }

  return null;
};

const validateAndTransformEpochIncomeLeaderboardData = (
  data: unknown
): EpochIncomeLeaderboard | null => {
  if (Array.isArray(data)) return null;
  if (isEpochIncomeLeaderboard(data)) {
    return data;
  }

  return null;
};

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? 'https://api.vx.tools'
    : 'http://localhost:4000';

const useBlockIncomeState = () => {
  const [data, setData] = useState<BlockIncome[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async (identity: string, epoch: number) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/blocks/income`,
        { identity, epoch },
        {
          timeout: 10000
        }
      );

      const validatedData = validateAndTransformData(response.data);
      if (validatedData === null) throw new Error('Data validation failed');
      setData(validatedData);
      setIsLoading(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled');
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData
  };
};

const useEpochIncomeState = () => {
  const [data, setData] = useState<Map<string, EpochIncome[]>>(new Map());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const fetchingSet = useRef<Set<string>>(new Set());

  const fetchData = useCallback(async (identity: string) => {
    if (fetchingSet.current.has(identity)) return;
    setIsLoading(true);
    fetchingSet.current.add(identity);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/epochs/income`,
        { identity, limit: 100 },
        {
          timeout: 10_000
        }
      );

      const validatedData = validateAndTransformEpochIncomeData(response.data);
      if (validatedData === null) throw new Error('Data validation failed');
      setData(prevData => {
        const newData = new Map(prevData);
        newData.set(identity, validatedData);
        return newData;
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled');
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
      fetchingSet.current.delete(identity);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData
  };
};

const useEpochVoteRecordState = () => {
  const [data, setData] = useState<Map<string, Map<number, VoterRecord>>>(
    new Map()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const fetchingSet = useRef<Set<string>>(new Set());

  const fetchData = useCallback(async (voteKey: string, epoch?: number) => {
    let fetchId = `${voteKey}:${epoch}`;
    if (fetchingSet.current.has(fetchId)) return;
    setIsLoading(true);
    fetchingSet.current.add(fetchId);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/epochs/votes`,
        { voteKey, epoch },
        {
          timeout: 15000
        }
      );

      const validatedData = validateAndTransformEpochVoteRecordData(
        response.data
      );
      if (validatedData === null) throw new Error('Data validation failed');
      if (
        epoch !== undefined &&
        voteKey === IDEAL_RECORD_KEY &&
        validatedData.votes.length === 0
      ) {
        setError(`No data found for epoch ${epoch}`);
      } else {
        setData(prevData => {
          const newData = new Map(prevData);
          const newVoterMap =
            newData.get(voteKey) || new Map<number, VoterRecord>();
          const resolvedEpoch = validatedData.epoch;
          newVoterMap.set(resolvedEpoch, validatedData.votes);
          newData.set(voteKey, newVoterMap);
          return newData;
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled');
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
      fetchingSet.current.delete(fetchId);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData
  };
};

const useEpochVotingLeaderboardState = () => {
  const [data, setData] = useState<Map<number, EpochVotingLeaderboard>>(
    new Map()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async (epoch?: number) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/epochs/leaderboard/voting`,
        { epoch },
        {
          timeout: 15000
        }
      );

      const validatedData = validateAndTransformEpochVotingLeaderboardData(
        response.data
      );
      if (validatedData === null) throw new Error('Data validation failed');
      setData(prevData => {
        const newData = new Map(prevData);
        newData.set(validatedData.epoch, validatedData);
        return newData;
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled');
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData
  };
};

const useEpochIncomeLeaderboardState = () => {
  const [data, setData] = useState<Map<number, EpochIncomeLeaderboard>>(
    new Map()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async (epoch?: number) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/epochs/leaderboard/income`,
        { epoch },
        {
          timeout: 30000
        }
      );

      const validatedData = validateAndTransformEpochIncomeLeaderboardData(
        response.data
      );
      if (validatedData === null) throw new Error('Data validation failed');
      setData(prevData => {
        const newData = new Map(prevData);
        newData.set(validatedData.epoch, validatedData);
        return newData;
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled');
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData
  };
};

export const VortexDataProvider: React.FC<VortexDataProviderProps> = ({
  children
}) => {
  const blockIncomeState = useBlockIncomeState();
  const epochIncomeState = useEpochIncomeState();
  const epochVoteRecordState = useEpochVoteRecordState();
  const epochVoteLeaderboardState = useEpochVotingLeaderboardState();
  const epochIncomeLeaderboardState = useEpochIncomeLeaderboardState();
  return (
    <VortexDataContext.Provider
      value={{
        blockIncomeState,
        epochIncomeState,
        epochVoteRecordState,
        epochVoteLeaderboardState,
        epochIncomeLeaderboardState
      }}
    >
      {children}
    </VortexDataContext.Provider>
  );
};
