import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  EpochVotingLeaderboard,
  VortexDataContext
} from 'providers/VortexDataProvider';
import EpochLeaderboardTable, {
  createEpochVotingLeaderboardTableColumns
} from 'components/tables/EpochVotingLeaderboardTable';
import { useNavigate, useParams } from 'react-router-dom';

export const leaderboardBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Cluster',
    url: '#!'
  },
  {
    label: 'Vote Stats',
    active: true
  }
];

interface SelectedEpoch {
  current: boolean;
  epoch?: number;
}

const ClusterVoteStats = () => {
  const context = useContext(VortexDataContext);
  const navigate = useNavigate();
  const { epoch } = useParams();
  const parsedEpoch = epoch ? Number.parseInt(epoch) : undefined;
  const epochParamIsValid =
    parsedEpoch !== undefined && !Number.isNaN(parsedEpoch);
  const [selectedEpoch, setSelectedEpoch] = useState<SelectedEpoch>({
    current: epoch === undefined,
    epoch: epochParamIsValid ? parsedEpoch : undefined
  });
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochVoteLeaderboardState &&
      !context.epochVoteLeaderboardState.isLoading &&
      !context.epochVoteLeaderboardState.error
    ) {
      if (!context.epochVoteLeaderboardState.data.size) {
        context.epochVoteLeaderboardState.fetchData();
      }

      if (!selectedEpoch.current && !!selectedEpoch.epoch) {
        if (!context.epochVoteLeaderboardState.data.has(selectedEpoch.epoch)) {
          context.epochVoteLeaderboardState.fetchData(selectedEpoch.epoch);
        }
      }
    }
  }, [context, selectedEpoch]);

  const { resolvedEpoch, leaderboardData } = useMemo(() => {
    if (!context || context.epochVoteLeaderboardState.data.size === 0)
      return {
        resolvedEpoch: undefined,
        leaderboardData: undefined
      };

    if (selectedEpoch.epoch !== undefined) {
      return {
        resolvedEpoch: selectedEpoch.epoch,
        leaderboardData: context.epochVoteLeaderboardState.data.get(
          selectedEpoch.epoch
        )
      };
    }

    const [currentEpoch, data] = context.epochVoteLeaderboardState.data
      .entries()
      .next().value;
    return {
      resolvedEpoch: currentEpoch,
      leaderboardData: data as EpochVotingLeaderboard
    };
  }, [context, selectedEpoch]);

  const computedTableColumns = useMemo(() => {
    const idealRecord = leaderboardData?.records.find(
      record => record.voteAddress === IDEAL_RECORD_KEY
    );
    return createEpochVotingLeaderboardTableColumns(idealRecord, resolvedEpoch);
  }, [leaderboardData]);

  const tableData = useMemo(() => {
    return leaderboardData?.records || [];
  }, [leaderboardData]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const epoch = Number.parseInt(event.target.value);
    setSelectedEpoch({
      current: false,
      epoch
    });
    navigate(`/cluster/vote-stats/epoch/${epoch}`);
  };

  const epochOptions = useMemo(() => {
    if (!context || context.epochVoteLeaderboardState.data.size === 0)
      return [
        {
          label: 'Current Epoch',
          value: undefined
        }
      ];

    // assume max is current
    let maxFetchedEpoch = 0;
    for (let blah of context.epochVoteLeaderboardState.data) {
      maxFetchedEpoch = Math.max(maxFetchedEpoch, blah[0]);
    }

    let options = [];
    let minSuggestedEpoch = Math.max(0, maxFetchedEpoch - 9);
    if (resolvedEpoch < minSuggestedEpoch + 4) {
      let minOldEpoch = Math.max(0, resolvedEpoch - 4);
      let maxOldEpoch = resolvedEpoch + 4;
      for (let i = minOldEpoch; i <= maxOldEpoch; i++) {
        options.push({
          label: `Epoch ${i}`,
          value: i
        });
      }
      options.push({
        label: '---',
        value: undefined
      });
      options.push({
        label: `Current Epoch (${maxFetchedEpoch})`,
        value: maxFetchedEpoch
      });
    } else {
      for (let i = minSuggestedEpoch; i <= maxFetchedEpoch; i++) {
        let label =
          i === maxFetchedEpoch
            ? `Current Epoch (${maxFetchedEpoch})`
            : `Epoch ${i}`;
        options.push({
          label,
          value: i
        });
      }
    }
    options.reverse();
    return options;
  }, [context, resolvedEpoch]);

  const epochTable = useAdvanceTable({
    data: tableData,
    columns: computedTableColumns,
    pageSize: 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    epochTable.setGlobalFilter(e.target.value || undefined);
  };

  if (!context) return <div>Loading...</div>;

  let { epochVoteLeaderboardState: epochLeaderboardState } = context;

  if (selectedEpoch.current && epochLeaderboardState.isLoading)
    return <div>Loading...</div>;
  if (epochLeaderboardState.error)
    return <div>Error: {epochLeaderboardState.error}</div>;

  return (
    <div>
      <PageBreadcrumb items={leaderboardBreadcrumbItems} />
      <div className="mb-9">
        <Row className="align-items-start justify-content-between mb-4 g-3">
          <Col xs="auto">
            <h3>Voting Leaderboard</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Vote accounts ranked by voted slots and earned vote credits
            </p>
          </Col>
        </Row>
        <Row className="align-items-start justify-content-between mb-4 g-3">
          <SearchBox placeholder="Search" onChange={handleSearchInputChange} />
          <Col xs={12} sm={4}>
            <Form.Select
              size="sm"
              onChange={handleSelectChange}
              value={resolvedEpoch}
            >
              {epochOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <AdvanceTableProvider {...epochTable}>
          {/* <div className="mx-n4 mx-lg-n6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1"> */}
          <div className="mx-n4 mx-lg-n6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <EpochLeaderboardTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ClusterVoteStats;
