import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import VoteLatencyPieChart, {
  useChartStats
} from './charts/e-charts/VoteQualityPieChart';
import { VortexDataContext } from 'providers/VortexDataProvider';
import { useAppContext } from 'providers/AppProvider';

interface VoteLatencyItem {
  value: number;
  label: string;
  color: string;
  className?: string;
}

const VoteLatencyItem = ({
  value,
  label,
  color,
  className
}: VoteLatencyItem) => {
  return (
    <div
      className={classNames(
        className,
        'd-flex flex-column flex-center align-items-sm-start flex-md-row justify-content-md-between flex-xxl-column p-3 ps-sm-3 ps-md-4 p-md-3 h-100'
      )}
    >
      <div className="d-flex align-items-center mb-1">
        <FontAwesomeIcon
          icon={faSquare}
          className={`fs-11 me-2 text-${color}`}
          transform="up-2"
        />
        <span className="mb-0 fs-9 text-body">{label}</span>
      </div>
      <h3 className="fw-semibold ms-xl-3 ms-xxl-0 pe-md-2 pe-xxl-0 mb-0 mb-sm-3">
        {value}
      </h3>
    </div>
  );
};

const VoteLatencyOverview = ({
  epoch,
  voteId
}: {
  epoch: number;
  voteId: string;
}) => {
  const {
    getThemeColor,
    config: { isDark }
  } = useAppContext();
  const context = useContext(VortexDataContext);
  const EPOCH = epoch;
  const VOTE_ID = voteId;
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochVoteRecordState &&
      !context.epochVoteRecordState.isLoading &&
      !context.epochVoteRecordState.data.size
    ) {
      context.epochVoteRecordState.fetchData(VOTE_ID, EPOCH);
      context.epochVoteRecordState.fetchData(IDEAL_RECORD_KEY, EPOCH);
    }
  }, [context]);

  const clusterData = context?.epochVoteRecordState.data
    .get(IDEAL_RECORD_KEY)
    ?.get(EPOCH);
  const tableData = context?.epochVoteRecordState.data.get(VOTE_ID)?.get(EPOCH);

  const chartStats = useChartStats({ clusterData, tableData });
  const dataList = useMemo(() => {
    if (chartStats === undefined) {
      return [];
    }

    return [
      {
        name: 'MIN (0)',
        color: 'success',
        value: chartStats.minLatency
      },

      {
        name: 'FAST (1)',
        color: 'primary',
        value: chartStats.fastLatency
      },

      {
        name: 'SLOW (>=2)',
        color: 'warning',
        value: chartStats.slowLatency
      },

      {
        name: 'MISSED SLOTS',
        color: 'danger',
        value: chartStats.skippedSlots
      }
    ];
  }, [chartStats]);

  const total = useMemo(() => {
    return dataList.reduce((acc, next) => acc + next.value, 0);
  }, [dataList]);

  return (
    <>
      <Row className="mt-6">
        <h3>Vote Latency</h3>
        <p className="text-body-tertiary">
          Vote latency relative to optimal inclusion
        </p>
      </Row>
      <Row className="mb-4 g-0">
        {dataList.map((item, index) => (
          <Col xs={6} key={item.name}>
            <VoteLatencyItem
              value={item.value}
              label={item.name}
              color={item.color}
              className={classNames('border-translucent', {
                'border-bottom border-end': index === 0,
                'border-bottom border-end-md-0': index === 1,
                'border-bottom border-end border-end-md': index === 2,
                'border-bottom': index === 3,
                'border-end': index === 4
              })}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default VoteLatencyOverview;
