import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { latencyToCredits } from 'helpers/utils';

export const createEpochVoteRecordTableColumns = (
  voteId: string,
  epoch: number,
  epochStartSlot: number | undefined,
  chunkSize: number,
  idealLatencies: number[][] | undefined
): ColumnDef<number[]>[] => {
  return [
    {
      accessorFn: (latencies, index) => {
        return index + 1;
      },
      header: 'Bucket',
      id: 'bucket',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      cell: ({ cell, row }) => {
        if (epochStartSlot === undefined) return;
        let bucketIndex = row.index;
        let slotRange = `${
          (epochStartSlot + bucketIndex * chunkSize) / chunkSize
        }000..999`;
        return (
          <a
            href={`/vote-history/${voteId}/epoch/${epoch}/bucket/${
              bucketIndex + 1
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {slotRange}
          </a>
        );
      },
      header: 'Slot Range',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latencies, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined) return 0;

        let latencySum = 0;
        let latencyCount = 0;
        for (let i = 0; i < latencies.length; i++) {
          if (latencies[i] === 0) continue;
          latencySum += latencies[i] - idealLatencyBucket[i];
          latencyCount++;
        }
        if (latencyCount === 0) return 0;
        return (latencySum / latencyCount).toFixed(3) + ' slots';
      },
      header: 'Avg Latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latencies, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined || idealLatencyBucket.length === 0)
          return 0;
        idealLatencyBucket = idealLatencyBucket.slice(0, latencies.length);
        const earnedCredits = latencies.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
        const optimalCredits = idealLatencyBucket.reduce(
          (acc, latency) => acc + latencyToCredits(latency),
          0
        );
        return optimalCredits - earnedCredits;
      },
      header: 'Missed Vote Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latencies, index) => {
        let idealLatencyBucket = idealLatencies?.at(index);
        if (idealLatencyBucket === undefined || idealLatencyBucket.length === 0)
          return 0;
        const missedSlots = latencies.reduce(
          (acc, latency) => acc + (latency === 0 ? 1 : 0),
          0
        );
        const optimalMissedSlots = idealLatencyBucket.reduce(
          (acc, latency) => acc + (latency === 0 ? 1 : 0),
          0
        );
        return missedSlots - optimalMissedSlots;
      },
      header: 'Missed Slots',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochVoteRecordTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
    </div>
  );
};

export default EpochVoteRecordTable;
