import React, { useMemo, useContext, useEffect, CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import dayjs from 'dayjs';
import { useAppContext } from 'providers/AppProvider';
import { VortexDataContext } from 'providers/VortexDataProvider';
import { TooltipComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { tooltipFormatterDefault } from 'helpers/echart-utils';
import { latencyToCredits } from 'helpers/utils';

echarts.use([TooltipComponent, BarChart]);

type YAxisData = {
  name: string;
  color: string;
  data: number[];
};

const getDefaultOptions = (
  xAxisData: number[],
  yAxisDataList: YAxisData[],
  getThemeColor: (name: string) => string
) => ({
  color: [getThemeColor('primary'), getThemeColor('tertiary-bg')],
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('body-highlight-bg'),
    borderColor: getThemeColor('tertiary-bg'),
    textStyle: { color: getThemeColor('light-text-emphasis') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      color: getThemeColor('secondary-color'),
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 10.24,
      padding: [0, 0, 0, 20]
    },
    splitLine: {
      show: true,
      interval: 1000,
      lineStyle: {
        color: getThemeColor('tertiary-bg')
      }
    },
    show: true,
    interval: 1,
    data: xAxisData,
    axisLine: {
      lineStyle: {
        color: getThemeColor('tertiary-bg')
      }
    },
    axisTick: false
  },
  yAxis: {
    axisPointer: { type: 'none' },
    position: 'right',
    axisTick: 'none',
    splitLine: {
      interval: 5,
      lineStyle: {
        color: getThemeColor('secondary-bg')
      }
    },
    axisLine: { show: false },
    axisLabel: {
      fontFamily: 'Nunito Sans',
      fontWeight: 700,
      fontSize: 12.8,
      color: getThemeColor('body-color'),
      margin: 20,
      verticalAlign: 'bottom',
      formatter: (value: string) => `${value.toLocaleString()}`
    }
  },
  series: yAxisDataList.map(({ name, color, data }) => ({
    name,
    type: 'bar',
    barWidth: '1px',
    data,
    label: {
      show: false,
      position: 'top',
      color: getThemeColor('body-color'),
      fontWeight: 'bold',
      fontSize: '10.24px'
    },
    z: 10,
    itemStyle: {
      borderRadius: [2, 2, 0, 0],
      color: getThemeColor(color)
    }
  })),
  grid: {
    right: 3,
    left: 6,
    bottom: 0,
    top: '5%',
    containLabel: true
  },
  animation: false
});

const EpochVoteBucketChart = ({
  epoch,
  bucket,
  style,
  voteId
}: {
  epoch: number;
  bucket: number;
  voteId: string;
  style: CSSProperties;
}) => {
  const { getThemeColor } = useAppContext();
  const context = useContext(VortexDataContext);
  const EPOCH = epoch;
  const EPOCH_START_SLOT = EPOCH * 432_000;
  const VOTE_ID = voteId;
  const GROUPING = 1;
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochVoteRecordState &&
      !context.epochVoteRecordState.isLoading &&
      !context.epochVoteRecordState.data.size
    ) {
      context.epochVoteRecordState.fetchData(VOTE_ID, EPOCH);
      context.epochVoteRecordState.fetchData(IDEAL_RECORD_KEY, EPOCH);
    }
  }, [context]);

  const clusterData = context?.epochVoteRecordState.data
    .get(IDEAL_RECORD_KEY)
    ?.get(EPOCH)
    ?.slice(1000 * bucket, 1000 * (bucket + 1));
  const xAxisData = useMemo(() => {
    return Array.from({ length: 1000 / GROUPING }, (_, index) => {
      return EPOCH_START_SLOT + bucket * 1000 + index * GROUPING;
    });
  }, [clusterData]);

  const tableData =
    context?.epochVoteRecordState.data
      .get(VOTE_ID)
      ?.get(EPOCH)
      ?.slice(1000 * bucket, 1000 * (bucket + 1)) || [];

  const yAxisData = useMemo(() => {
    let ticks = Math.min(
      (clusterData?.length || 0) / GROUPING,
      (tableData?.length || 0) / GROUPING
    );
    if (ticks === 0 || !clusterData || !tableData) return [];

    let validatorSeries = {
      name: 'Missed Credits',
      color: 'danger',
      data: [] as number[]
    };

    for (let i = 0; i < ticks; i++) {
      let clusterSlice = clusterData.slice(i * GROUPING, (i + 1) * GROUPING);
      let clusterCredits = clusterSlice.reduce(
        (acc, latency) => acc + latencyToCredits(latency),
        0
      );
      let validatorSlice = tableData.slice(i * GROUPING, (i + 1) * GROUPING);
      let validatorCredits = validatorSlice.reduce(
        (acc, latency) => acc + latencyToCredits(latency),
        0
      );
      let missedCredits = clusterCredits - validatorCredits;
      validatorSeries.data.push(missedCredits);
    }

    return [validatorSeries];
  }, [clusterData, tableData]);

  if (!context) return <div>Loading...</div>;

  let { epochVoteRecordState } = context;

  if (epochVoteRecordState.error)
    return <div>Error: {epochVoteRecordState.error}</div>;

  return (
    <ReactEChartsCore
      echarts={echarts}
      showLoading={epochVoteRecordState.isLoading}
      option={getDefaultOptions(xAxisData, yAxisData, getThemeColor)}
      style={style}
    />
  );
};

export default EpochVoteBucketChart;
